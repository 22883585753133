import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

function PopUp(props) {
const strings = [
  "Por favor, no cerrar la pestaña del navegador hasta que todas las fotos hayan sido procesadas. De lo contrario, puede que algunas fotos no sean subidas al servidor.",
  "La carrera está siendo creada. Por favor, no cierre o refresque la pestaña del navegador hasta que esta ventanilla desaparezca.",
  "La carrera está siendo editada. Por favor, no cierre o refresque la pestaña del navegador hasta que esta ventanilla desaparezca."
]

  return (
    <div>
      <Dialog open={props.open}>
      {/* <Button className="close-button" onClick={props.handleClose} style={{ display: 'flex', justifyContent: 'flex-end' }}> */}
        {/* <span className="close-x">X</span> */}
    {/* </Button> */}
        <DialogTitle>Atencion</DialogTitle>

        <DialogContent>
          <p>{strings[props.message]}</p>
          {/* <p>{`${props.imagesProcessed} de ${props.images} han sido procesadas`}</p> */}
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PopUp;
