import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ButtonAppBar from '../NavBar/NavBar';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from '@material-ui/core';
import NavBar from '../NavBar/NavBar';
import ClosingPopUp from '../ConditionalComponents/PopUp/ClosingPopUp';
import DataTable from '../SeeResults/DataTable/DataTable'
import { useEffect, useState } from 'react';
import {OrganizerId, Domain, Frontend_Server} from '../Constants';
import { useFetcher } from 'react-router-dom';

const cards = Array.from({ length: 100 }, (_, i) => i + 1);// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


// Example usage
const downloadAll = async (urls) => {
  // Create and append a link
  let link = document.createElement("a");
  document.documentElement.append(link);

  const imgArr = urls;
  for (let i = 0; i < imgArr.length; i++) {
    await fetch(imgArr[i].src)
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(blob => {

        let objectURL = URL.createObjectURL(blob);

        // Set the download name and href
        link.setAttribute("download", `image_${i}.jpg`);
        link.href = objectURL;

        // Auto click the link
        link.click();
    })
  }
};
function downloadZip(zipUrl) {
  const filename = `fotos.zip`;
  const anchor = document.createElement('a');
  anchor.href = zipUrl;
  anchor.download = filename;
  anchor.style.display = 'none'; // Hide the anchor element
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}
export default function Album() {
  const [races, setRaces] = useState([]);
  const [pics, setPics] = useState([]);
  const [race, setRace] = useState([])
  const [runnerId, setRunnerId] = useState('')
  const [email, setEmail] = useState('')
  const [openVal, setOpen] = useState(false)

  function downloadImage(imageUrl, idx) {
    const filename = `my_image${idx}.jpg`;
    const anchor = document.createElement('a');
    anchor.href = imageUrl;
    anchor.download = filename;
    anchor.style.display = 'none'; // Hide the anchor element
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  function downloadZip(imageUrl) {
    let data = {
      urls: pics
    }
    fetch(`${Frontend_Server}downloadImages`, {
      method: 'POST', 
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
  }).then(response => response.text())
  .then(resp => downloadZipToComputer(JSON.parse(resp).url))
  .catch(error => {
      console.error('Error:', error);
  });

  }

  function downloadZipToComputer(url) {
    console.log(`url zip: ${url}`)
    const filename = `fotos.zip`;
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
    anchor.style.display = 'none'; // Hide the anchor element
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

    
  
  function downloadImages() {
    console.log(pics)
    for (let idx = 0; idx < pics.length; idx++) {
      downloadImage(pics[idx], idx)
    }
  }



  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      runnerId: runnerId,
      raceName: race,
      email: email
  };
  alert("Las fotos han sido enviadas a tu correo con éxito! Revisa la sección de “correo no deseado” o “spam”")
  setOpen(true)
  fetch(`${Frontend_Server}sendImagesByEmail`, {
      method: 'POST', 
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
  })
  .catch(error => {
      console.error('Error:', error);
  })
  }


  useEffect(() => {
    fetch(`${Frontend_Server}getAllRaces?organizerId=${OrganizerId}`)
    .then(response => response.json())
    .then(data => {
      setRaces(data);
    })
      .catch(error => console.error('Error:', error));
  }, []);

  useEffect(() => {
    console.log(pics)
  }, [pics]);
  function searchForPics() {
    fetch(`${Frontend_Server}getImages?raceName=${encodeURIComponent(race)}&runnerId=${runnerId}`)
    .then(response => response.json())
    .then(data => {
      console.log(data)
      setPics([...data])
      })

  }
  return (

    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <ClosingPopUp open={openVal}/>
      <ButtonAppBar />
      
 
      <main>
      <Stack direction="row" sx={{ pt: 4 }} spacing={2} justifyContent="center">

<TextField id="outlined-basic2" value={runnerId} label="Numero de corredor" variant="outlined" sx={{input: { }}} style={{color : "#ffffff"}} onChange={(e) => {
  setRunnerId(e.target.value)}}/>

<TextField id="outlined-basic" label="Email" variant="outlined" onChange={(e) => {setEmail(e.target.value)}}/>
    
  <Button variant="contained" style={{backgroundColor: "#ffffff", color : "#0971f1"}} onClick={handleSubmit}>Mandar a Email</Button>
  </Stack>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Mis Fotos
            </Typography>


            < Stack direction="row" spacing={2} justifyContent="center">
              
            <Stack
              sx={{ pt: 4 , width: "400px"}}
              direction="column"
              spacing={2}
              justifyContent="center"
            >
                          <FormControl >
                        <InputLabel id="demo-simple-select-label">Carrera</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={race}
                            label="Age"
                            onChange={(e) => setRace(e.target.value)}
                        >
                            {races.map((op, idx) => <MenuItem  key={idx} value={op[1]}>{op[1]}</MenuItem>)}
                        </Select>
              </FormControl>
            <TextField id="outlined-basic" label="Numero de corredor" value = {runnerId} variant="outlined"  onChange={
              (e) => setRunnerId(e.target.value)}
/>

                            
              
              <Button variant="contained" onClick={searchForPics}>Buscar</Button>
              <Button variant="contained" onClick={() => downloadZip(pics)}>Descargar</Button>
            </Stack>
            

            </Stack>

          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {pics.map((str) => (
              <Grid item key={str} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      // 16:9
                      pt: '56.25%',
                    }}
                    image={str}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
}