import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function ClosingPopUp(props) {
  const strings = [
    "Por favor, no cerrar la pestana del navegador hasta que todas las fotos hayan sido procesadas. De lo contrario puede que algunas fotos no sean subidas al servidor",
    "La carrera esta siendo creada. Por favor no cierre o refresque la pestana del brower hasta que esta ventanilla desaparezca",
    "Las fotos llegaran a su email en unos pocos minutos. Revise su spam folder"
  ];

  const [open, setOpen] = useState(props.open);  // Manage popup state

  const handleClose = () => {
    setOpen(false);
    props.handleClose?.(); // Call parent's handleClose if provided
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>
          Atención
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}  // Position close button
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <p>{strings[2]}</p>
          {/* <p>{`${props.imagesProcessed} de ${props.images} han sido procesadas`}</p> */}
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ClosingPopUp;
