import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Debug_Domain, Frontend_Server } from '../../Constants'
import { Grid } from '@mui/material'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
function LazyLoadingImages(props) {
    
const [page, setPage] = useState(0)
const [images, setImages] = useState([])



  return (
    <div>
        <Grid container spacing={4}>
            {props.images.map((str) => (
              <Grid item key={str} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      // 16:9
                      pt: '0%',
                    }}
                    image={str}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
    </div>
  )
}

export default LazyLoadingImages