import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Input, ToggleButton } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdminNavBar from "../NavBar/AdminNavBar";
import NavBar from "../NavBar/NavBar";
import { useState } from "react";
import FileUpload from "../Buttons/FileUpload";
import GetFile from "../Buttons/GetFile";
import { ToggleButtonGroup } from "@mui/material";
import { Domain, Debug_Domain, Frontend_Server } from "../Constants";
import PopUp from "../ConditionalComponents/PopUp/PopUp";
if (localStorage.getItem("authenticated") === undefined) {
  window.location.href = "/carreras";
}


const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9]; // TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
const partition = 20
const maxImgs = 200
export default function AddEvent() {
  const [raceName, setRaceName] = useState("");
  const [files, setFiles] = useState([]);
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [spreadsheet, setSpreadsheet] = useState("");
  const [isSpreadsheetUploaded, setIsSpreadsheetUploaded] = useState("");
  const [imagesProcessed, setImagesProcessed] = useState([]);
  const [openPopup, setopenPopup] = useState(false)
  function handleResponse(response) {
    response.json().then((data) => {
      if (!data.error) {
        setopenPopup(true)
        sendImagesWithLimit(data.race_id);
      } else {
        alert(data.error);
      }
    });
  }
  const handleSpreadsheetChange = (event) => {
    setIsSpreadsheetUploaded(true);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        setSpreadsheet(text);
      };
      reader.readAsText(file);
    }
  };
  const handleUploadImages = (event) => {
    setFiles(event.target.files);
  };

  // function sendImage(file, raceId) {
  //   const reader = new FileReader();
  //   reader.onloadend = (e) => {
  //     const arrayBuffer = e.target.result;
  //     // Option 1: Process the image data as an ArrayBuffer (preferred for further manipulation)
  //     // Handle the ArrayBuffer data here (e.g., resize, compress)

  //     // Option 2: Convert to base64 string for transmission (if needed)
  //     const base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));

  //     let data = {
  //       username: localStorage.getItem("username"),
  //       password: localStorage.getItem("password"),
  //       img: base64String,
  //       raceId: raceId
  //     }
  //     // Send the request asynchronously using fetch
  //     fetch(`${Domain}addImage`, {
  //       method: 'POST',
  //       body: JSON.stringify(data),
  //       headers: {'Content-Type': 'application/json'}
  //     });

  //   };
  //   reader.readAsArrayBuffer(file);

  // }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (raceName === "") {
      alert("No escogió un nombre");
      return;
    } else if (date === "") {
      alert("No escogió una fecha");
      return;
    } else if (location === "") {
      alert("No escogió ubicación");
      return;
    } else if (files.length === 0) {
      alert("No escogió imágenes");
      return;
    }
    let data = {
      username: localStorage.getItem("username"),
      password: localStorage.getItem("password"),
      name: raceName,
      date: date,
      location: location,
      spreadsheet: spreadsheet,
      number_of_images: files.length,
    };
    const formData = new FormData();
    formData.append("name", raceName); // Changed from 'raceName' to 'name'
    formData.append("date", date);
    formData.append("location", location);
    formData.append("spreadsheet", spreadsheet); // Make sure 'spreadsheet' is defined and holds the CSV data
    formData.append("number_of_images", files.length); // Adding the number of images

    // Replace 'YOUR_API_ENDPOINT' with the actual endpoint URL
    fetch(`${Domain}createRace/`, {
      // Changed to the '/createRace' endpoint
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    }).then((response) => handleResponse(response));
    // .then(response => response.json()).then( data => {
    //   if (!data.error) {
    //     sendImages(data.race_id)
    //   } else {
    //     alert(data.error)
    //   }
    // })
    // .then(
    //   e => {
    //     alert(e.statusText)
    //   });

    // Handle the response

    // sendImages(raceId)
  };
  async function sendImagesWithLimit(raceId) {
    let filesArray = Array.from(files)
    let allImgs = []
    for (let offset = 0; offset < Math.ceil(files.length / maxImgs);offset++) {
      await sendImages(raceId, offset * maxImgs, filesArray)
    }
    // alert("Se creo la carrera exitosamente");
    setopenPopup(false)
  }

  async function sendImages(raceId, offset, filesArray) {
    let allImgs = []
    for (let idx = 0; idx < Math.ceil(maxImgs / partition);idx++) {
      let slice = filesArray.slice(offset + partition*idx, offset + partition* (idx + 1))
      if (slice.length > 0) {
        allImgs.push(slice)

      }
    }
    const promises = await Promise.allSettled(allImgs.map(imgs20 => sendImageBy20s(imgs20, raceId)))
    // alert("Se creo la carrera exitosamente");
  }

  async function sendImageBy20s(imgs, raceId) {
    // const xhr = new XMLHttpRequest();
    // const formData = new FormData()
    // formData.append("img", image)
    // formData.append("raceId", raceId)
    // formData.append("username", localStorage.getItem("username"))
    // formData.append("password", localStorage.getItem("password"))
    // xhr.open("POST", `${Domain}addImage`)
    // xhr.send(formData)
    const formData = new FormData();
    formData.append("username", localStorage.getItem("username"));
    formData.append("password", localStorage.getItem("password"));
    for (const [idx, img] of imgs.entries()) {
      formData.append(`img${idx}`, img);
    }
    formData.append("raceId", raceId);
    // Send the request asynchronously using fetch
    return fetch(`${Domain}addImage`, {
      method: "POST",
      body: formData,
      keepalive: true,
    })
  //   .catch(
  //     () => 
  //       {
  //         setImagesProcessed([...imagesProcessed] + [2])
  //       }
  //   ).then(
  //     () => 
  //       {
  //         setImagesProcessed([...imagesProcessed] + [2])
  //       }
  //   );
  }

  // async function sendImages(raceId) {
  //   for (const image of files) {
  //     try {
  //       // Construct a FormData object to send the image
  //       sendImage(image, raceId)

  //       console.log(`Image uploaded successfully: ${image.name}`);
  //     } catch (error) {
  //       console.error(`Error uploading image: ${error.message}`);
  //     }
  //   }
  // }

  return (
    <ThemeProvider theme={defaultTheme}>
      <PopUp imagesProcessed={imagesProcessed} images={files.length} open={openPopup} handleClose={() => setopenPopup(false)} message={1}/>
      <CssBaseline />
      <AdminNavBar position="relative">
        <Toolbar />
      </AdminNavBar>
      <main>
        <Container sx={{ py: 8 }}>
          <FormControl sx={{ width: "100%" }}>
            <Stack direction="column" sx={{ width: "100%" }} marginBottom={10}>
              <Box marginBottom={4}>
                <Typography variant="body1"> Nombre de Carrera </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setRaceName(e.target.value);
                  }}
                />
              </Box>

              <Box marginBottom={4}>
                <Typography variant="body1">
                  {" "}
                  Fecha de carrera (DD-MM-AAAA)
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
              </Box>

              <Box marginBottom={4}>
                <Typography variant="body1"> Ubicacion </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                />
              </Box>
              <Box marginBottom={4}>
                <Typography variant="body1">
                  {" "}
                  Imágenes (Solo Imágenes .png, .jpg o .jpeg){" "}

                </Typography>

                <Button variant="contained" color="primary" component="label">
                  Sube el Archivo
                  <input
                    type="file"
                    multiple
                    accept=".png,.jpg,.jpeg"
                    style={{ display: "none" }}
                    onChange={handleUploadImages}
                  />
                </Button>
                {files.length !== 0 && (
                  <div>{`Se subieron ${files.length} arhivos`}</div>
                )}
              </Box>
              <Typography variant="body1">
                {" "}
                Información de Participantes (No Cambiar la Estrctura del
                modelo, solo agregue información)
              </Typography>

              <GetFile
                fileName="https://runnerpictures.blob.core.windows.net/spreadsheets/spreadsheet.csv"
                label="Descarga el Modelo"
              />
              <Box marginBottom={4}>
                <Typography variant="body1">
                  {" "}
                  Imágenes (Solo archivos csv){" "}
                </Typography>

                <Button variant="contained" color="primary" component="label">
                  Sube el Archivo
                  <input
                    type="file"
                    accept=".csv"
                    style={{ display: "none" }}
                    onChange={handleSpreadsheetChange}
                  />
                </Button>
                {isSpreadsheetUploaded && <div>{`Se subio la hoja`}</div>}
              </Box>
            </Stack>

            <Button
              onClick={handleSubmit}
              variant="contained"
              style={{ width: "160px" }}
            >
              Crear Carrera
            </Button>
          </FormControl>
        </Container>
      </main>
    </ThemeProvider>
  );
}
