import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavBar from '../NavBar/NavBar';
import ButtonAppBar from '../NavBar/NavBar';
import { useState } from 'react';
import { Domain, Frontend_Server } from '../Constants';
// import { useNavigate } from "react-router-dom";

// if (localStorage.getItem("authenticated") !== undefined) {

//   window.location.href = "/agregar"
// }

// TODO remove, this demo shouldn't need to reset the theme.

export default function SignIn() {
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  function handleSubmit(event) {
    event.preventDefault();
    const data = {
      username: username,
      password: password,
    }
    const url = `${Frontend_Server}authenticate`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(response => response.json()).then(data => {
      if (!data.error) {
        localStorage.setItem("authenticated", true)
        localStorage.setItem("username", username)
        localStorage.setItem("password", password)
        window.location.href = "/agregar"
      } else {
        alert("Usuario y contrasena no son validos")
      }
        
    })
    .catch(error => {
        console.error('Error:', error);
    });
  };


  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user"
              label="Usuario"
              name="user"
              onChange={(e) => {setUsername(e.target.value)}}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contrasena"
              type="password"
              id="password"
              onChange={(e) => {setPassword(e.target.value)}}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}