import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import DataTable from './DataTable/DataTable';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import ButtonAppBar from '../NavBar/NavBar';
import NavBar from '../NavBar/NavBar';
import {useState, useEffect} from 'react';
import {OrganizerId, Domain, Frontend_Server} from '../Constants'

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


export default function FindRace() {
  const [races, setRaces] = useState([])
  useEffect(() => {
    fetch(`${Frontend_Server}getAllRaces?organizerId=${OrganizerId}`)
    .then(response => response.json())
    .then(data => {
      setRaces(data);
    })
      .catch(error => console.error('Error:', error));
  }, []);
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <NavBar position="relative">
        <Toolbar />
      </NavBar>            
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container>
          <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >

            </Stack>

          </Container>
        </Box>
        <DataTable rows={races}/>
      </main>
      {/* Footer */}

      {/* End footer */}
    </ThemeProvider>
  );
}