import React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import { Route } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Box } from '@mui/material';
// function RoutableButton() {
//   const history = useHistory();

//   const navigateToRoute = () => {
//     history.push("/target-route");
//   };

//   return <Button onClick={navigateToRoute}>Go to Target Route</Button>;
// }

export default function NavBar() {
  return (
      <div>
        <AppBar position="static">
          <Toolbar>
            {/* <Button color="inherit" onClick={() => { window.location.href = "/carreras"; }}>Carreras</Button> */}
            <Button color="inherit" onClick={() => { window.location.href = "/fotos"; }}>Mis Fotos</Button>
            <Button color="inherit" onClick={() => { window.location.href = "/todas-fotos"; }}>Todas las fotos</Button>
            <Button color="inherit" onClick={() => { window.location.href = "/fotos-no-reconocidas"; }}>Fotos no reconocidas</Button>
            {/* <Button color="inherit" onClick={() => { window.location.href = "/results"; }}>Resultados</Button> */}
            
            {/* Add a Box to push the image to the right */}
            <Box sx={{ flexGrow: 1 }} />
            
            {/* Image at the rightmost part */}
            <img 
              src="https://runnerpictures.blob.core.windows.net/logo/logo.jpeg" 
              alt="Logo" 
              style={{ height: '60px', cursor: 'pointer',borderRadius: '50%',  }}
              onClick={() => { window.location.href = "/"; }} 
            />
          </Toolbar>
        </AppBar>
      </div>
  );
}