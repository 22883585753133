import Link from '@mui/material/Link';

function GetFile(props) {
  return (
    <Link href={props.fileName} download>
      {props.label}
    </Link>
  );
}

export default GetFile;
