import React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import { Route } from 'react-router-dom';
import { Box } from '@mui/material';

// import { useHistory } from "react-router-dom";

// function RoutableButton() {
//   const history = useHistory();

//   const navigateToRoute = () => {
//     history.push("/target-route");
//   };

//   return <Button onClick={navigateToRoute}>Go to Target Route</Button>;
// }

export default function NavBar() {
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Button color="inherit" onClick={() => {window.location.href = "/editar"}}>Editar Carrera</Button>
          <Button color="inherit" onClick={() => {window.location.href = "/agregar"}}>Agregar Carrera</Button>
          <Box sx={{ flexGrow: 1 }} />

          <img 
              src="https://runnerpictures.blob.core.windows.net/logo/logo.jpeg" 
              alt="Logo" 
              style={{ height: '60px', cursor: 'pointer',borderRadius: '50%',  }}
              onClick={() => { window.location.href = "/"; }} 
            />
        </Toolbar>
      </AppBar>
    </div>
  );
}