import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { Input, ToggleButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AdminNavBar from '../NavBar/AdminNavBar';
import NavBar from '../NavBar/NavBar';
import { useState } from 'react';
import FileUpload from '../Buttons/FileUpload';
import GetFile from '../Buttons/GetFile';
import {ToggleButtonGroup} from '@mui/material';
import {Select} from '@mui/material';
import { MenuItem } from '@material-ui/core';
import { useEffect } from 'react';
import {Frontend_Server, OrganizerId} from '../Constants';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { Domain, Debug_Domain } from '../Constants';
import PopUp from '../ConditionalComponents/PopUp/PopUp';
if (localStorage.getItem("authenticated") === undefined) {
  window.location.href = "/carreras"
}



const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const partition = 20
const maxImgs = 200
export default function EditEvent() {
  const [raceName, setRaceName] = useState("")
  const [files, setFiles] = useState([])
  const [races, setRaces] = useState([]);
  const [raceId, setRaceId] = useState("")
  const [date, setDate] = useState("")
  const [location, setLocation] = useState("")
  const [openPopup, setopenPopup] = useState(false)

  function handleResponse(response) {
    response.json().then(data => {
        if (!data.error) {
          setopenPopup(true)
          sendImagesWithLimit(raceId)
        } else {
          alert(data.error)
        }
      })

} 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (raceName === "") {
      alert("No escogió un nombre");
      return;
    }
    let data = {
      raceId: raceId,
      username: localStorage.getItem("username"),
      password: localStorage.getItem("password"),
      date: date,
      location: location,
      number_of_images: files.length
    }
  
    // Replace 'YOUR_API_ENDPOINT' with the actual endpoint URL
    fetch(`${Domain}editRace/`, {
      // Changed to the '/createRace' endpoint
      method: "POST",
      body: JSON.stringify(data),
      headers: {'Content-Type': 'application/json'},
    }).then(response => handleResponse(response));
  };
 

  async function sendImagesWithLimit(raceId) {
    let filesArray = Array.from(files)
    let allImgs = []
    for (let offset = 0; offset < Math.ceil(files.length / maxImgs);offset++) {
      await sendImages(raceId, offset * maxImgs, filesArray)
    }
    // alert("Se creo la carrera exitosamente");
    setopenPopup(false)
  }

  async function sendImages(raceId, offset, filesArray) {
    let allImgs = []
    for (let idx = 0; idx < Math.ceil(maxImgs / partition);idx++) {
      let slice = filesArray.slice(offset + partition*idx, offset + partition* (idx + 1))
      if (slice.length > 0) {
        allImgs.push(slice)

      }
    }
    const promises = await Promise.allSettled(allImgs.map(imgs20 => sendImageBy20s(imgs20, raceId)))
    // alert("Se creo la carrera exitosamente");
  }

  async function sendImageBy20s(imgs, raceId) {
    // const xhr = new XMLHttpRequest();
    // const formData = new FormData()
    // formData.append("img", image)
    // formData.append("raceId", raceId)
    // formData.append("username", localStorage.getItem("username"))
    // formData.append("password", localStorage.getItem("password"))
    // xhr.open("POST", `${Domain}addImage`)
    // xhr.send(formData)
    const formData = new FormData();
    formData.append("username", localStorage.getItem("username"));
    formData.append("password", localStorage.getItem("password"));
    for (const [idx, img] of imgs.entries()) {
      formData.append(`img${idx}`, img);
    }
    formData.append("raceId", raceId);
    // Send the request asynchronously using fetch
    return fetch(`${Domain}addImage`, {
      method: "POST",
      body: formData,
      keepalive: true,
    })
  //   .catch(
  //     () => 
  //       {
  //         setImagesProcessed([...imagesProcessed] + [2])
  //       }
  //   ).then(
  //     () => 
  //       {
  //         setImagesProcessed([...imagesProcessed] + [2])
  //       }
  //   );
  }

  useEffect(() => {
    fetch(`${Frontend_Server}getAllRaces?organizerId=${OrganizerId}`)
    .then(response => response.json())
    .then(data => {
      setRaces(data);
    })
      .catch(error => console.error('Error:', error));
  }, []);
  function chooseEntry(name) {
    for (const race of races) {
      if (race[1] === name) {
        return race
      }
    }

  }

// <div
// style={{
//   position: 'fixed',
//   top: 0,
//   left: 0,
//   width: '100vw',
//   height: '100vh',
//   backgroundColor:'white' , // Set white background when loading
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   zIndex: 9999
// }}
// >
// {<CircularProgress />} {/* Show spinner while loading */}
// {/* Your other content */}
// </div>

// <div style={{
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100vw',
//     height: '100vh',
//     backgroundColor:'white' , // Set white background when loading
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     zIndex: 9999
//   }}>
// {<LinearProgress />}
// </div>


  return (
    
    <ThemeProvider theme={defaultTheme}>
      <PopUp message={2} images={files.length} open={openPopup} handleClose={() => setopenPopup(false)}/>
      <CssBaseline />
      <AdminNavBar position="relative">
        <Toolbar />
      </AdminNavBar>
      <main>
        <Container sx={{ py: 8 }} >

            
                
                <FormControl sx = {{width: "100%"}}>

                    <Stack direction="column" sx = {{width: "100%"}} marginBottom={10}> 

                    <Box marginBottom={4}>
                        <Typography variant = "body1" > Nombre de Carrera </Typography>
                    </Box>
                    <Select
        sx = {{width: "100%"}}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={raceName}
            label="Age"
            onChange={e => 
              {
                const entry = chooseEntry(e.target.value)
                setRaceId(entry[0])
                setLocation(entry[2])
                setDate(entry[3])
                setRaceName(e.target.value)}}
        >
            {races.map((op, idx) => <MenuItem key={idx} value={op[1]}>{op[1]}</MenuItem>)}
        </Select>
                    <Box marginBottom={4}>
                    <Typography variant = "body1" > Fecha de carrera (DD-MM-AAAA) </Typography>
                        <TextField id="outlined-basic" variant="outlined" sx = {{width: "100%"}} value={date} onChange={e => setDate(e.target.value)}/>
                    </Box>

                    <Box marginBottom={4}>
                    <Typography variant = "body1"> Ubicacion </Typography>
                        <TextField id="outlined-basic" variant="outlined" sx = {{width: "100%"}} value={location} onChange={e => setLocation(e.target.value)} />
                    </Box>
                    <Box marginBottom={4}>
                    <Typography variant = "body1"> Agregar mas Imágenes (Solo Archivos .png,.jpg,.jpeg) </Typography>
                    <Button variant="contained" color="primary" component="label">
                  Sube el Archivo
                  <input
                    type="file"
                    multiple
                    accept=".png,.jpg,.jpeg"
                    style={{ display: "none" }}
                    onChange={e => setFiles(e.target.files)}
                  />
                </Button>
                {files.length !== 0 && (
                  <div>{`Se subieron ${files.length} arhivos`}</div>
                )}
                    </Box>
                    {/* <Typography variant = "body1"> Información de Participantes (No Cambiar la Estrctura del modelo, solo agregue Información)</Typography>
                    <GetFile fileName = "rand.csv" label = "Descarga el Modelo"/>
                    <FileUpload /> */}
      
                    </Stack>
                        
                    <Button onClick={handleSubmit} variant="contained" style={{width: "180px"}}>Editar Carrera</Button>
                    

                
                </FormControl>

          
        </Container>
      </main>
      
    </ThemeProvider>
  );
}