import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ButtonAppBar from '../NavBar/NavBar';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from '@material-ui/core';
import NavBar from '../NavBar/NavBar';
import ClosingPopUp from '../ConditionalComponents/PopUp/ClosingPopUp';
import DataTable from '../SeeResults/DataTable/DataTable'
import { useEffect, useState } from 'react';
import {OrganizerId, Domain, Frontend_Server, Debug_Domain} from '../Constants';
import { useFetcher } from 'react-router-dom';
import LazyLoadingImages from './LazyLoadingImages/LazyLoadingImages';
const cards = Array.from({ length: 100 }, (_, i) => i + 1);// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();



export default function SeeAllPhotos() {
  const [races, setRaces] = useState([]);
  const [pics, setPics] = useState([]);
  const [race, setRace] = useState([])
  const [runnerId, setRunnerId] = useState('')
  const [email, setEmail] = useState('')
  const [openVal, setOpen] = useState(false)

  const [page, setPage] = useState(0)
  const [images, setImages] = useState([])
  const [hide, setHide] = useState(true)

function getMoreImages() {
    console.log( `imgs: `)
    console.log(pics)

    fetch(`${Frontend_Server}getPaginatedImages?organizerId=${OrganizerId}&page=${page + 1}&raceName=${encodeURIComponent(race)}`)
    .then(response => response.json())
    .then(data => {
        console.log(`picsdata ${[pics, ...data]}`)
        setPics([...pics, ...data])
        if (data.length === 0) {
            setHide(true)
        }
    })
    setPage(page + 1)

}


  useEffect(() => {
    fetch(`${Frontend_Server}getAllRaces?organizerId=${OrganizerId}`)
    .then(response => response.json())
    .then(data => {
      setRaces(data);
    })
      .catch(error => console.error('Error:', error));
  }, []);


  // useEffect(() => {
  //   fetch(`${Debug_Domain}getPaginatedImages?${page + 1}&raceName=${encodeURIComponent(race)}`)
  //   .then(response => response.json())
  //   .then(data => setImages([pics, ...data]))
  // }, [page, pics, race]);

  useEffect(() => {
    console.log(pics)
  }, [pics]);
  function searchForPics() {
        fetch(`${Frontend_Server}getPaginatedImages?page=${0}&raceName=${encodeURIComponent(race)}&organizerId=${OrganizerId}`)
    .then(
        response => response.json())
    .then(
        data => {
            console.log(`picsdata ${[pics, ...data]}`)
            setHide(false)
            setPics([...data])
            setPage(1)
        })
  }

  return (

    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <ClosingPopUp open={openVal}/>
      <ButtonAppBar />
      
 
      <main>
      <Stack direction="row" sx={{ pt: 4 }} spacing={2} justifyContent="center">

  </Stack>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">

          <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Todas las Fotos
            </Typography>
            < Stack direction="row" spacing={2} justifyContent="center">
              
            <Stack
              sx={{ pt: 4 , width: "400px"}}
              direction="column"
              spacing={2}
              justifyContent="center"
            >
                          <FormControl >
                        <InputLabel id="demo-simple-select-label">Carrera</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={race}
                            label="Age"
                            onChange={(e) => setRace(e.target.value)}
                        >
                            {races.map((op, idx) => <MenuItem  key={idx} value={op[1]}>{op[1]}</MenuItem>)}
                        </Select>
              </FormControl>

                            
              
              <Button variant="contained" onClick={searchForPics}>Buscar</Button>
            </Stack>
            

            </Stack>

          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          <div className='image-grid'>
          <LazyLoadingImages race={race} images={pics} />
          <Button onClick={getMoreImages} variant="contained" style={{
    display: hide ? 'none' : 'block',
    width: '100%', // Fills the container width
    margin: '0 auto', // Centers horizontally
  }}>
             Ver mas imagenes
          </Button>
          </div>
        </Container>
      </main>
    </ThemeProvider>
  );
}