import './App.css';
import { BrowserRouter as Router, Route, Switch, Link, BrowserRouter, Routes } from 'react-router-dom';
import RaceOrganizerLogin from './RaceOrganizerLogin/RaceOrganizerLogin';
import SeeYourPhotos from './SeeYourPhotos/SeeYourPhotos';
import SeeResults from './SeeResults/SeeResults';
import AddEvent from './SubmitInfoOrganizer/AddEvent';
import FindRace from './FindRace/FindRace';
import NavBar from './NavBar/NavBar';
import EditEvent from './SubmitInfoOrganizer/EditEvent';
import SeeAllPhotos from './SeeAllPhotos/SeeAllPhotos';
import UnrecognizedImages from './UnrecognizedPictures/UnrecognizedPictures';

sessionStorage.isAuthenticated = true
function App() {
  
  return (
    
    <Router>
      <Routes>
      <Route path="/" element={<SeeYourPhotos />} />
    <Route path="/results" element={<SeeResults />} />
    <Route path="/organizador" element = {<RaceOrganizerLogin />} />
    <Route path="/fotos" element = {<SeeYourPhotos />} />
    <Route path="/carreras" element={<FindRace />} />
    <Route path="/agregar" element={<AddEvent />} />
    <Route path="/editar" element={<EditEvent />} />
    <Route path="/todas-fotos" element={<SeeAllPhotos />} />
    <Route path="/fotos-no-reconocidas" element={<UnrecognizedImages />} />


      </Routes>

    </Router>
  );
}


export default App;
