import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import DataTable from './DataTable/DataTable';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Menu, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import NavBar from '../NavBar/NavBar';
import { useState, useEffect } from 'react';

import {OrganizerId, Domain, Frontend_Server} from '../Constants';
// if ("/agregar" === window.location.href.substring(window.location.href.length - 8) && !sessionStorage.isAuthenticated) {
//   window.location.href = "/organizador"
// }



function findFieldsForRace() {
  

}





const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


export default function SeeResults() {
  let  x = ["opts", "opts2"]
  // console.log("hey")
  let [raceOptions, setRaceOptions] = useState([]);
  let [distanceOptions, setDistanceOptions] = useState([]);
  let [categoryOptions, setCategoryOptions] = useState([]);
  let [branchOptions, setBranchOptions] = useState([]);
  let [rows, setRows] = useState([])


  let [race, setRace] = useState('');
  let [branch, setBranch] = useState('');
  let [category, setCategory] = useState('');
  let [distance, setDistance] = useState('');
  let [lastName, setLastName] = useState('');
  function setFieldForAllFilters(event)  {
    const name = event.target.value
    setRace(event.target.value)
    fetch(`${Frontend_Server}getRaceStatsFieldOptions?raceName=${encodeURIComponent(name)}&fieldName=distance`)
    .then(response => response.json())
    .then(data => {
      setDistanceOptions([...data]);
    })
    console.log(distanceOptions)
    console.log(branchOptions)
    console.log(categoryOptions)

    fetch(`${Frontend_Server}getRaceStatsFieldOptions?raceName=${encodeURIComponent(name)}&fieldName=category`)
    .then(response => response.json())
    .then(data => {
      setCategoryOptions([...data]);
    })


    fetch(`${Frontend_Server}getRaceStatsFieldOptions?raceName=${encodeURIComponent(name)}&fieldName=branch`)
    .then(response => response.json())
    .then(data => {
      setBranchOptions([...data]);
    })
  }
  useEffect(() => {
    fetch(`${Frontend_Server}getAllRaces?organizerId=${OrganizerId}`)
    .then(response => response.json())
    .then(data => {
      setRaceOptions(data);
    })
      .catch(error => console.error('Error:', error));
  }, []);

  useEffect(() => {
    console.log(distanceOptions)
    console.log(branchOptions)
    console.log(categoryOptions)
    console.log(rows)


  }, [distanceOptions, branchOptions, categoryOptions, rows]);




  function searchResults() {
    let data = {};
    if (branch !== "") {
      data["branch"] = branch
    }
    if (category !== "") {
      data["category"] = category
    }
    if (distance !== "") {
      data["distance"] = distance
    }
    if (lastName !== "") {
      data["lastName"] = lastName
    }

    fetch(`${Frontend_Server}getFilteredRaceStats?raceName=${encodeURIComponent(race)}&${new URLSearchParams(data).toString()}`)
    .then(response => response.json())
    .then(data => {
  console.log(data)
  setRows(data)
})
  }




  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <NavBar />
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container>
          <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Stack direction="column" width = "33%">
              <FormControl >
                        <InputLabel id="demo-simple-select-label3">Carrera</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select7"
                            value={race}
                            label="Age"
                            onChange={setFieldForAllFilters}

                            
                        >
                          {raceOptions.map((op, idx) => <MenuItem  key={idx} value={op[1]}>{op[1]}</MenuItem>)}
                        </Select>
              </FormControl>

                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Rama</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select8"
                            value={branch}
                            label="Age"
                            onChange={(e) => setBranch(e.target.value)}
                        >
                          {branchOptions.map((op, idx) => <MenuItem key={idx} value={op}>{op}</MenuItem>)}

                        </Select>
                    </FormControl>
              </Stack>

              <Stack direction="column" width = "33%">
                  <FormControl>
                            <InputLabel id="demo-simple-select-label2" >Distancia</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select9"
                                value={distance}
                                // value={'10'}
                                label="Age"
                                onChange={(e) => setDistance(e.target.value)}
                                >
                            {distanceOptions.map((op, idx) => <MenuItem key={idx} value={op}>{op}</MenuItem>)}
                              
                            </Select>
                        </FormControl>
                        <FormControl>
                        <TextField id="standard-basic" label="Apellido de Corredor" onChange={(e) => setLastName(e.target.value)} />

                        </FormControl>

              </Stack>
              <Stack direction="column" width = "33%">
                  <FormControl>
                            <InputLabel id="demo-simple-select-label" >Categoria</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select10"
                                value={category}

                                
                                label="Categoria"
                                onChange={(e) => setCategory(e.target.value)}
                                >
                          {categoryOptions.map((op, idx) => <MenuItem value= {op} key={idx}>{op}</MenuItem>)}
                              
                            </Select>
                        </FormControl>
                        <FormControl>
                        <Button  variant="contained" style={{ height: '55px' }} onClick={searchResults}>Buscar</Button>

                        </FormControl>

              </Stack>


            </Stack>

          </Container>
        </Box>
        <DataTable rows = {rows}/>
      </main>
    </ThemeProvider>
  );
}